(function($){
    [...document.querySelectorAll('.fade-in--cascade')].forEach(el => el.classList.add("fade-in--animating"));
    [...document.querySelectorAll('.featured-tenant-image__img')].forEach(el => {
        el.onclick = function() {
            let src = el.getAttribute('data-src');
            let featured = $('.featured-tenant-image__wrapper');
            $('.featured-tenant-image__img--active').removeClass('featured-tenant-image__img--active');
            el.classList.add('featured-tenant-image__img--active');
            featured.fadeOut('slow', function () {
                featured.css({ 'background-image': 'url(' + src + ')' });
                featured.fadeIn();
            });
        }
    })
})(jQuery);