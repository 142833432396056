/**
 * File public.js.
 *
 * Webpack entrypoint for public js and css
 */

// css
import './sass/app.scss';
// scripts
import './js/lazyLoad.js';
import './js/app.js';