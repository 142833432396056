import isInViewport from './isInViewport.js';

function lazyLoad(load = false) {
    [...document.querySelectorAll('.fade-in')].forEach((el, i) => {
        if (isInViewport(el)) {
            el.classList.add('fade-in--animating');
            if (true == load) {
                if ( !el.classList.contains('fade-in--cascade') ) {
                    el.classList.add(`fade-in--vp--${i}`);
                }
            }
        }
    });
}

window.addEventListener('DOMContentLoaded', lazyLoad(true), false);
window.addEventListener('scroll', lazyLoad);
window.addEventListener('resize', lazyLoad);